<template>
  <div>
    <div class="datapage-box">
      <div class="bankbox">
        <img src="@/assets/images/new/back-icon.png" alt="" @click="bankgo">
        <p class="bankname">{{ title }}</p>
      </div>
      <div class="titlebox">
        <div class="titleitem" v-for="item in  ArticleData" :key="item.id" :class="{'actitle':acindex==item.id}" @click="Toarticle(item)">{{item.title}}</div>
      </div>

      <div class="Title">
        <p>{{ title }}</p>
        <div class="Title-lower"></div>
      </div>
      <div class="datapage-box-info">
        <p v-html="pagehtml"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailData, getArticleData } from "@/network/api.js";

export default {
  data() {
    return {
      pagehtml: null,
      title: "",
      ArticleData: [],//信息数据列表
      acindex: null
    };
  },
  //activated
  created() {
    this.getlist();
    this.gettipsdata();
    this.acindex = this.$route.query.id;
  },
  watch: {
    "$route.query.id"(nv) {
      if (nv != undefined) {
        this.getlist();
      }
    },
  },
  methods: {
    // 获得隐私信息列表
    gettipsdata() {
      getArticleData().then((res) => {
        this.ArticleData = res.data.data;
      });
    },
    Toarticle(v) {
      this.acindex = v.id;
      this.$router.push({
        path: "/Datapage",
        query: {
          id: v.id,
        },
      });
    },
    bankgo() {
      this.$router.push({
        path: "/About",
      });
    },
    // 根据id获得文章数据
    getlist() {
      getDetailData(this.$route.query.id).then((res) => {
        this.title = res.data.data.title;
        this.pagehtml = res.data.data.content;
      });
    },
  },
};
</script>

<style lang="scss">
.datapage-box {
  width: 3.5rem;
  margin: 0 auto;
  .Title p {
    display: inline-block;
    margin: 0 auto;
    font-size: 0.3rem;
    font-weight: 400;
    color: #cccccc;
    line-height: 0.66rem;
    text-transform: uppercase;
    position: relative;
    font-family: "FZZ";
  }
  .bankbox {
    width: 3.35rem;
    margin: 0 auto;
    height: 0.44rem;
    display: flex;
    align-items: center;
    background: #020715;
    text-align: center;
    position: relative;
    img {
      position: absolute;
      width: 0.25rem;
      height: 0.14rem;
    }
    .bankname {
      width: 100%;
      text-align: center;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.16rem;
      color: #ffffff;
    }
  }
  .titlebox {
    display: flex;
    .titleitem {
      font-size: 0.12rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #636363;
      margin: 0.05rem;
    }
    .actitle {
      color: #c5bbed;
    }
  }
  .datapage-box-info {
    margin-top: 0.1rem;

    .title {
      font-size: 0.18rem;
      color: #73d171;
      height: 0.5rem;
      line-height: 0.5rem;
    }

    p {
      font-size: 0.16rem;
      color: #fff;
      line-height: 1.75em;

      img {
        width: 100% !important;
        height: unset !important;
      }
    }
  }
}
</style>
